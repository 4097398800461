@import 'scss/main.scss';

.ArchitecturePage {
  .ArchitecturePageInner {
    max-width: 1000px;
    width: calc(100% - $xl-padding * 4);
    padding: calc($xl-padding * 4);

    .BasicRagImage {
      max-width: 450px;
      width: 100%;
    }

    .ParameterComparisionImage {
      max-width: 650px;
      width: 100%;
    }

    .ComputeComparisionImage {
      max-width: 800px;
      width: 100%;
    }

    .GptResponseImage {
      max-width: 600px;
      width: 100%;
    }

    .RagIllustrationImage {
      max-width: 1000px;
      width: 100%;
    }

    .BaseGraphImage {
      max-width: 800px;
      width: 100%;
    }

    .ReplicaGraphImage {
      max-width: 1000px;
      width: 100%;
    }

    .LocalArchImage {
      max-width: 600px;
      width: 100%;
    }
    
    .BloomGraphImage {
      max-width: 800px;
      width: 100%;
    }

    .Neo4jConversationTreesImage {
      max-width: 800px;
      width: 100%;
    }

    .Neo4jConversationTreeImage {
      max-width: 800px;
      width: 100%;
    }

    .FinalArchImage {
      max-width: 600px;
      width: 100%;
    }
  }
}