/*-- Colors -- */
$base-black: black;
$base-white: white;
$dust: #f2f3f4;
$heavy-dust: #eeeeee;
$stainless-steel: #727272;
$prussian: #152574;
$active-black: rgb(68, 67, 67);
$transparent: rgba(0,0,0,0);
$rice-paper: rgba(255, 255, 255, 0.775);
/*-- Typeography --*/
    /*-- Fonts --*/
    $font-family: 'Helvetica Neue', sans-serif;
    /*-- Sizing --*/
    $xs-font-size: 16px;
    $sm-font-size: 20px;
    $lg-font-size: 40px;
    /*--Line Heights--*/
    $nav-line-height: 28px;
    /*-- Alternate Colors --*/
/*-- Borders --*/
    /*-- Color --*/
    /*-- Radius --*/
/*-- Spacing --*/
    /*-- Padding --*/
    $xs-padding: 3px;
    $sm-padding: 5px;
    $lg-padding: 10px;
    $xl-padding: 15px;
    /*-- Margin --*/
    $xs-margin: 3px;
    $sm-margin: 5px;
    $md-margin: 7px;
    $lg-margin: 10px;
    $xl-margin: 15px;
/*-- Animation --*/
$compactTransition: all ease 0.3s;
$easeInTransition: easeInTransition(0.3s);
$slowEaseInTransition: easeInTransition(0.5s);

@function easeInTransition($seconds) {
    @return ease-in $seconds;
}
