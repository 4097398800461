@import 'scss/main.scss';


$arrowButtonDiameter: 45px;
$spaceBetweenInputAndButton: $xl-margin;

.LandingPageInner {
  width: 100vw;
}

.EmptyChatMessage {
  position: absolute;
  font-size: $xs-font-size;
  color: $stainless-steel;
  height: 100vh;
  max-width: calc(100vw - ($nav-line-height) * 2);
  pointer-events: none;

  .EmptyChatMessageInner {
    text-align: center;
  }
}

.Chat {
  max-width: 1000px;
  height: calc(100vh - $xl-padding * 2);
  padding: $xl-padding;
  width: calc(100% - $xl-padding * 2);

  .ChatInner {
    height: 100%;
  }
}

.ChatInput {
  $inputWidth: calc(100% - 1px * 2 - $arrowButtonDiameter - $spaceBetweenInputAndButton * 2);

  height: 100px;
  width: 100%;
  border: 1px solid $base-black;
  border-radius: 10px;

  .ChatInputInnerContainerVertical {
    height: 100%;
    width: 100%;
  }

  .ChatInputInnerContainerHorizontal {
    width: 100%;
  }

  textarea {
    height: 80px;
    padding-left: $xl-padding;
    font-size: $xs-font-size;
    width: $inputWidth;
    resize: none;
    outline: none;
    margin-right: $spaceBetweenInputAndButton;
    border: 0px;
    background-color: $transparent;
  }
}

.MessageContainer {
  padding: $lg-padding;

  .Origin {
    font-weight: 600;
  }

  .Message {
    font-size: $xs-font-size;
  }
}

.arrow-button {
  position: relative;
  height: $arrowButtonDiameter;
  width: $arrowButtonDiameter;
  margin-right: $spaceBetweenInputAndButton;
  border-radius: 10%;
  transition: $compactTransition;
  
  .arrowhead {
    background-color: $base-black;
    opacity: 1;
    height: 15px;
    width: 4px;
    display: inline-block;
    position: absolute;
    top: 8px;
  
    &.left {
      transform: rotate(45deg);
      left: 16px;
    }
  
    &.right {
      transform: rotate(-45deg);
      right: 16px;
    }
  }
  
  .arrowstem {
    height: 25px;
    width: 4px;
    background-color: $base-black;
    display: inline-block;
    position: absolute;
    border: 0px;
    top: 10px;
    left: 20px;
  }

  &:hover {
    background-color: $dust;
    cursor: pointer;

    .arrowhead, .arrowstem {
      background-color: $prussian;
    }
  }

  &:active {
    background-color: $base-white;

    .arrowhead, .arrowstem {
      background-color: $active-black;
    }
  }

  &.isDisabled {
    cursor: wait;

    .arrowstem {
      border: 4px solid $dust; /* Light grey */
      border-top: 4px solid $base-black; /* Blue */
      background-color: $base-white;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      left: 5px;
      top: 5px;
      animation: spin 2s linear infinite;
    }

    .arrowhead {
      opacity: 0;
    }

    &:hover {
      background-color: $base-white;
  
      .arrowhead, .arrowstem {
        background-color: $base-white;
      }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}